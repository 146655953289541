/*!
 * Copyright 2022
 * Mystory Norge As
 * https://mystory-norge.no
 */
export const NumberFormat = (props: { format: string; value: number }) => {
  const m = /\{(?<tsep>.)(?<dsep>.)(?<dec>\d+)\}/.exec(props.format)!;
  if (!m) return <></>;

  const s: string[] = [];
  // Add prefix
  if (m.index) {
    s.push(props.format.substring(0, m.index));
  }

  // Format number
  const decimals = +m!.groups!.dec;
  const [vi, vd] = props.value.toFixed(decimals).split('.');
  const valueH: string[] = [];

  for (let i = 0; i < vi.length; i++) {
    if (i % 3 === 0 && i !== 0) valueH.push(m.groups!.tsep);
    valueH.push(vi[vi.length - i - 1]);
  }
  s.push(valueH.reverse().join(''), m.groups!.dsep, vd);

  // Add suffix
  if (m.index + m.length + 1 < props.format.length) {
    s.push(props.format.substring(m.index + m.length + 1));
  }

  return <span>{s.join('')}</span>;
};
