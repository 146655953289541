/*!
 * Copyright 2022
 * Mystory Norge As
 * https://mystory-norge.no
 */
import './App.css';
import { ShareLinks } from './features/linker/ShareLinks';
import { DropCustomerInfo, DropLines, DropTotals } from './features/dropLines/DropLines';
import head from './head.webp';
import { environment } from './env';

function App() {
  return (
    <main>
      {!environment.production && (
        <div className="alert alert-danger">
          <h5>Eksempel</h5>
          Dette er et eksempelutkast.
        </div>
      )}

      <div className="py-5 text-center">
        <h2>Tilbudskalkulator</h2>
      </div>

      <div className="row">
        <div className="col-7">
          <DropCustomerInfo />
        </div>
        <div className="col-5 text-end img-head">
          <img src={head} alt="Gabionfabrikken" />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <DropLines />
        </div>
      </div>

      <div className="row">
        <div className="col-8 text-start"></div>
        <div className="col-4 text-end">
          <DropTotals />
          <ShareLinks />
        </div>
      </div>

      <div className="row">
        <div className="col"></div>
      </div>
    </main>
  );
}

export default App;
