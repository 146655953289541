/*!
 * Copyright 2022
 * Mystory Norge As
 * https://mystory-norge.no
 */
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAppSelector } from '../../app/hooks';
import { environment } from '../../env';
import { selectProducts } from '../products/productsSlice';

const setQS = (q: URLSearchParams, n: string, v: any) => v && q.set(n, '' + v);

const buildMailtoUrl = (to: string, bcc: string, subject: string, body: string[]): string => {
  if (!environment.production) {
    subject = `Eksempel ${subject}`;
    body.unshift('Eksempelutkast\r\n');
  }
  return `mailto:${to}?${bcc ? `bcc=${encodeURIComponent(bcc)}&` : ''}subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body.join('\r\n'))}`;
};

export const ShareLinks = () => {
  const { customer, lines, newLine, wantsRings } = useAppSelector(selectProducts);
  const q = new URLSearchParams();

  setQS(q, 'n', customer.name);
  setQS(q, 'm', customer.phone);
  setQS(q, 'e', customer.email);
  setQS(q, 'a', customer.address);
  setQS(q, 'z', customer.postCode);
  setQS(q, 'l', customer.place);
  lines.forEach((l) => setQS(q, `p${parseInt(l.product, 36) % 10000}`, l.quantity.v));
  if (newLine.product) setQS(q, `p${parseInt(newLine.product, 36) % 10000}`, newLine.quantity.v);
  if (wantsRings) setQS(q, 'r', '1');

  const shareMessage = ['Hei', '', 'Se på dette tilbudet:', `${window.location.href}?${q.toString()}`];
  const orderMessage = ['Hei', '', 'Jeg ønsker å bestille følgende:', `${window.location.href}?${q.toString()}`];

  return (
    <>
      <Button variant="secondary" className="d-print-none" onClick={() => window.print()}>
        Skriv ut tilbud
      </Button>
      <Button
        variant="secondary"
        className="d-print-none ms-1"
        href={buildMailtoUrl('', 'wetting@unikwall.no', 'Tilbud', shareMessage)}
      >
        Del tilbud
      </Button>
      {!customer.name ||
      !customer.phone ||
      !customer.email ||
      !customer.address ||
      !customer.postCode ||
      !customer.place ? (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Venligst fyll ut alle feltene, slik at vi kan kontakte deg om din bestilling.</Tooltip>}
        >
          <span>
            <Button variant="primary" className="d-print-none ms-1" disabled>
              Send bestilling
            </Button>
          </span>
        </OverlayTrigger>
      ) : (
        <Button
          variant="primary"
          className="d-print-none ms-1"
          href={buildMailtoUrl('wetting@unikwall.no', '', 'Bestilling', orderMessage)}
        >
          Send bestilling
        </Button>
      )}
    </>
  );
};
