/*!
 * Copyright 2022
 * Mystory Norge As
 * https://mystory-norge.no
 */
import { faExclamationCircle, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FormControl, FormSelect, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { NumberFormat } from '../components/NumberFormat';
import {
  addLine,
  PartSlug,
  removeLine,
  selectProducts,
  setCustomer,
  setLine,
  setWantsRings
} from '../products/productsSlice';

export const DropCustomerInfo = () => {
  const { customer } = useAppSelector(selectProducts);
  const dispatch = useAppDispatch();

  return (
    <Table size="sm">
      <tbody>
        <tr>
          <th className="text-start">Navn</th>
          <td colSpan={2}>
            <FormControl
              value={customer.name}
              onChange={(e) =>
                dispatch(
                  setCustomer([
                    e.target.value,
                    customer.phone,
                    customer.email,
                    customer.address,
                    customer.postCode,
                    customer.place
                  ])
                )
              }
            />
          </td>
        </tr>
        <tr>
          <th className="text-start">Telefon</th>
          <td colSpan={2}>
            <FormControl
              value={customer.phone}
              onChange={(e) =>
                dispatch(
                  setCustomer([
                    customer.name,
                    e.target.value,
                    customer.email,
                    customer.address,
                    customer.postCode,
                    customer.place
                  ])
                )
              }
            />
          </td>
        </tr>
        <tr>
          <th className="text-start">E-post</th>
          <td colSpan={2}>
            <FormControl
              value={customer.email}
              onChange={(e) =>
                dispatch(
                  setCustomer([
                    customer.name,
                    customer.phone,
                    e.target.value,
                    customer.address,
                    customer.postCode,
                    customer.place
                  ])
                )
              }
            />
          </td>
        </tr>
        <tr>
          <th className="text-start">Adresse</th>
          <td colSpan={2}>
            <textarea
              className="form-control"
              value={customer.address}
              onChange={(e) =>
                dispatch(
                  setCustomer([
                    customer.name,
                    customer.phone,
                    customer.email,
                    e.target.value,
                    customer.postCode,
                    customer.place
                  ])
                )
              }
            />
          </td>
        </tr>
        <tr>
          <th className="text-start">Postnummer/sted</th>
          <td>
            <input
              className="form-control"
              size={2}
              value={customer.postCode}
              onChange={(e) =>
                dispatch(
                  setCustomer([
                    customer.name,
                    customer.phone,
                    customer.email,
                    customer.address,
                    e.target.value,
                    customer.place
                  ])
                )
              }
            />
          </td>
          <td>
            <FormControl
              value={customer.place}
              onChange={(e) =>
                dispatch(
                  setCustomer([
                    customer.name,
                    customer.phone,
                    customer.email,
                    customer.address,
                    customer.postCode,
                    e.target.value
                  ])
                )
              }
            />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

const DropProductLineWarning = (props: { message: string; show?: boolean }) => {
  if (props.show === false) {
    return <></>;
  }
  return (
    <OverlayTrigger placement="bottom" overlay={<Tooltip>{props.message}</Tooltip>}>
      <span>
        <FontAwesomeIcon icon={faExclamationCircle as any} />
      </span>
    </OverlayTrigger>
  );
};

const DropProductLine = (props: { line: number }) => {
  const { categories, products, lines, newLine, wantsRings, needsSpirals } = useAppSelector(selectProducts);
  const dispatch = useAppDispatch();
  const line = props.line < 0 ? newLine : lines[props.line];
  const product = products.find((p) => p.slug === line.product);

  return (
    <>
      <tr>
        <td className="d-print-none text-warning">
          {!!line.product.length &&
            wantsRings &&
            needsSpirals &&
            !product?.parts?.cRings &&
            !product?.parts?.cRings1600 && (
              <>
                <DropProductLineWarning message="Dette produktet kan ikke leveres med c-ringer, og vil derfor bli levert med koblingsspiraler." />
              </>
            )}
        </td>
        <td>
          <FormSelect
            value={line.category}
            onChange={(e) => dispatch(setLine([props.line, e.target.value, '', line.quantity.d]))}
          >
            {props.line < 0 && <option></option>}
            {categories.map((g) => (
              <option key={g.slug} value={g.slug}>
                {g.name}
              </option>
            ))}
          </FormSelect>
        </td>
        <td>
          <FormSelect
            value={line.product}
            onChange={(e) => dispatch(setLine([props.line, line.category, e.target.value, line.quantity.d]))}
          >
            {props.line < 0 && <option></option>}
            {products
              .filter((p) => categories.find((c) => c.slug === line.category)?.products.includes(p.slug))
              .map((p) => (
                <option key={p.slug} value={p.slug}>
                  {p.name}
                </option>
              ))}
          </FormSelect>
        </td>
        <td>{line.dimensions}</td>
        <td className="text-end">
          <FormControl
            className="text-end"
            type="number"
            style={{ maxWidth: '8vw', minWidth: '50px' }}
            value={line.quantity.d}
            onChange={(e) => dispatch(setLine([props.line, line.category, line.product, e.target.value]))}
          />
        </td>
        <td className="text-end">
          <NumberFormat format="{ ,2} kg" value={line.weight} />
        </td>
        <td className="text-end">
          <NumberFormat format="{ ,2} kr" value={line.price} />
        </td>
        <td className="text-end">
          <NumberFormat format="{ ,2} kr" value={line.priceTotal} />
        </td>
        <td className="d-print-none text-end">
          <Button
            size="sm"
            variant={props.line < 0 ? 'success' : 'danger'}
            onClick={() => dispatch(props.line < 0 ? addLine() : removeLine(props.line))}
          >
            <FontAwesomeIcon icon={(props.line < 0 ? faPlus : faMinus) as any} />
          </Button>
        </td>
      </tr>
      {/*!!line.product.length && wantsRings && needsSpirals && !product?.parts?.cRings && !product?.parts?.cRings1600 && (
        <tr key={1}>
          <td className="d-print-none"></td>
          <td colSpan={7}>
            <Alert variant="warning" className="mb-0">
              Dette produktet kan kan ikke leveres med c-ringer, og vil derfor bli levert med koblingsspiraler.
            </Alert>
          </td>
          <td className="d-print-none"></td>
        </tr>
      )*/}
    </>
  );
};

const DropPartLine = (props: { first: boolean; line: PartSlug }) => {
  const { partLines, parts, wantsRings } = useAppSelector(selectProducts);
  const dispatch = useAppDispatch();
  const line = partLines[props.line]!;
  const part = parts[props.line];
  const partActive = props.line.startsWith('cRings') === wantsRings;

  return (
    <tr>
      <td className="d-print-none">
        {props.first && (
          <FormCheckInput
            type="radio"
            checked={partActive}
            onChange={(e) =>
              e.target.checked && dispatch(setWantsRings(props.line.startsWith('cRings') === e.target.checked))
            }
          />
        )}
      </td>
      <td>
        <FormControl readOnly value="Monteringsutstyr" />
      </td>
      <td>
        <FormControl readOnly value={part.name} />
      </td>
      <td></td>
      <td className="text-end">
        <FormControl className="text-end" style={{ maxWidth: '8vw', minWidth: '50px' }} value={line} readOnly />
      </td>
      <td className="text-end">
        <NumberFormat format="{ ,2} kg" value={line * part.weight} />
      </td>
      <td className="text-end">
        <NumberFormat format="{ ,2} kr" value={part.price} />
      </td>
      <td className="text-end">
        <NumberFormat format="{ ,2} kr" value={line * part.price} />
      </td>
      <td className="d-print-none text-end"></td>
    </tr>
  );
};

const DropProductLines = () => {
  const { lines } = useAppSelector(selectProducts);

  return (
    <>
      {lines.map((_, i) => (
        <DropProductLine key={i} line={i} />
      ))}
      <DropProductLine line={-1} />
    </>
  );
};

const DropPartLines = () => {
  const { partLines } = useAppSelector(selectProducts);

  return (
    <>
      {Object.keys(partLines)
        .sort((a, b) => (a.startsWith('cRings') ? 1 : b.startsWith('cRings') ? -1 : a.localeCompare(b)))
        .map((k, i, a) => (
          <DropPartLine
            key={k}
            first={i === 0 || i === a.findIndex((l) => l.startsWith('cRings'))}
            line={k as PartSlug}
          />
        ))}
    </>
  );
};

export const DropLines = () => {
  return (
    <>
      <Table size="sm" hover striped className="align-middle">
        <thead>
          <tr>
            <th className="d-print-none"></th>
            <th>Kategori</th>
            <th>Produkt</th>
            <th>Dimensjoner</th>
            <th className="text-end">Antall</th>
            <th className="text-end">Vekt</th>
            <th className="text-end">Enhetspris</th>
            <th className="text-end">Pris eks. MVA</th>
            <th className="d-print-none"></th>
          </tr>
        </thead>
        <tbody>
          <DropProductLines />
          <DropPartLines />
        </tbody>
        <tfoot>
          <tr>
            <th className="d-print-none"></th>
            <th>Kategori</th>
            <th>Produkt</th>
            <th>Dimensjoner</th>
            <th className="text-end">Antall</th>
            <th className="text-end">Vekt</th>
            <th className="text-end">Enhetspris</th>
            <th className="text-end">Pris eks. MVA</th>
            <th className="d-print-none"></th>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export const DropTotals = () => {
  const { totals } = useAppSelector(selectProducts);

  return (
    <Table size="sm">
      <tbody>
        <tr>
          <td></td>
          <th className="text-start">Totalvekt</th>
          <td>
            <NumberFormat format="{ ,1} kg" value={totals.weight} />
          </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <th className="text-start">Før skatt</th>
          <td>
            <NumberFormat format="{ ,2} kr" value={totals.priceExTax} />
          </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <th className="text-start">MVA</th>
          <td>
            <NumberFormat format="{ ,2} kr" value={totals.tax} />
          </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <th className="text-start">Total</th>
          <td>
            <NumberFormat format="{ ,2} kr" value={totals.priceIncTax} />
          </td>
          <td></td>
        </tr>
      </tbody>
    </Table>
  );
};
