/*!
 * Copyright 2022
 * Mystory Norge As
 * https://mystory-norge.no
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { round } from '../../tools';
import data from '../../data.json';

interface NumVal {
  d: string;
  v: number;
}

export type PartSlug = 'dist03' | 'dist05' | 'dist10' | 'spiral03' | 'spiral05' | 'spiral10' | 'cRings' | 'cRings1600';

const setNumVal = (n: NumVal | undefined, v: number | string | null, d: number = 0) => {
  if (n) {
    if (typeof v === 'number') {
      if (!isFinite(v)) v = d;
      n.d = '' + v;
      n.v = v;
    } else if (typeof v === 'string') {
      n.d = v;
      if (!isFinite(+v)) v = d;
      n.v = +v;
    } else {
      n.d = '' + d;
      n.v = d;
    }
  }
};

interface Customer {
  name: string;
  address: string;
  postCode: string;
  place: string;
  phone: string;
  email: string;
}

interface Category {
  slug: string;
  name: string;
  products: string[];
}

interface Product {
  slug: string;
  name: string;
  dimensions: string;
  weight: number;
  price: number;
  parts?: Partial<Record<PartSlug, number>>;
}

interface ProductLine {
  category: string;
  product: string;
  quantity: NumVal;
  dimensions: string;
  weight: number;
  price: number;
  priceTotal: number;
}

interface Totals {
  weight: number;
  priceExTax: number;
  tax: number;
  priceIncTax: number;
}

export interface ProductsState {
  divisor: number;
  customer: Customer;
  categories: Category[];
  parts: Record<PartSlug, { name?: string; price: number; weight: number }>;
  products: Product[];
  lines: ProductLine[];
  newLine: ProductLine;
  needsSpirals: boolean;
  wantsRings: boolean;
  partLines: Partial<Record<PartSlug, number>>;
  totals: Totals;
}

export const initialState: ProductsState = {
  ...data,
  customer: {
    name: '',
    address: '',
    place: '',
    postCode: '',
    phone: '',
    email: ''
  },
  lines: [],
  newLine: {
    category: '',
    product: '',
    quantity: { d: '1', v: 1 },
    dimensions: '',
    weight: 0,
    price: 0,
    priceTotal: 0
  },
  partLines: {},
  needsSpirals: false,
  wantsRings: false,
  totals: {
    weight: 0,
    priceExTax: 0,
    tax: 0,
    priceIncTax: 0
  }
};

const updateTotals = (state: ProductsState) => {
  const partLines: Partial<Record<PartSlug, number>> = {};
  state.needsSpirals = false;

  state.lines
    .concat([state.newLine])
    .filter((l) => !!l.product?.length)
    .map<[ProductLine, Product?]>((l) => [l, state.products.find((p) => p.slug === l.product)])
    .filter((l) => !!l[1] && !!l[1].parts)
    .forEach(([l, p]) =>
      Object.keys(p!.parts!).forEach((i) => {
        partLines[i as PartSlug] ??= 0;
        if (i.startsWith('cRings') === state.wantsRings) {
          partLines[i as PartSlug]! += p!.parts![i as PartSlug]! * l.quantity.v;
        } else if (state.wantsRings && !p!.parts!['cRings']) {
          state.needsSpirals = true;
          partLines[i as PartSlug]! += p!.parts![i as PartSlug]! * l.quantity.v;
        }
        if (i.startsWith('cRings') && p!.parts!['cRings']) {
          partLines.cRings1600 = 0;
        }
      })
    );

  if (partLines.cRings && partLines.cRings > 0) {
    partLines.cRings1600 = round(partLines.cRings! / 1600);
  }
  if (partLines.cRings1600 === 0) {
    delete partLines.cRings1600;
  } else {
    delete partLines.cRings;
  }

  state.partLines = partLines;

  // Calculate totals for all lines and parts
  state.totals.weight = round(
    // Line weight is pre-calculated
    state.lines.reduce((p, c) => p + c.weight, 0) +
      state.newLine.weight +
      Object.keys(state.partLines).reduce(
        (p, c) => p + state.parts[c as PartSlug].weight * state.partLines[c as PartSlug]!,
        0
      ),
    2
  );
  state.totals.priceExTax = round(
    state.lines.reduce((p, c) => p + c.quantity.v * c.price, 0) +
      state.newLine.priceTotal +
      Object.keys(state.partLines).reduce(
        (p, c) => p + state.parts[c as PartSlug].price * state.partLines[c as PartSlug]!,
        0
      ),
    2
  );

  // Calculate tax
  state.totals.tax = round(state.totals.priceExTax * 0.25, 2);
  state.totals.priceIncTax = round(state.totals.priceExTax + state.totals.tax, 2);
};

const updateLine = (line: ProductLine, state: ProductsState) => {
  const product = state.products.find((p) => p.slug === line.product);
  if (line.quantity.v < 1) {
    line.quantity.d = '1';
    line.quantity.v = 1;
  }
  line.dimensions = product ? product.dimensions : '';
  line.price = product ? product.price : 0;
  line.priceTotal = line.price * line.quantity.v;
  line.weight = product ? product.weight * line.quantity.v : 0;
  updateTotals(state);
};

Object.keys(initialState.parts).forEach((k) => (initialState.parts[k as PartSlug].price /= initialState.divisor));
initialState.products.forEach((p) => (p.price /= initialState.divisor));
if (window.location.search) {
  const q = new URLSearchParams(window.location.search);
  q.forEach((v, k) => {
    if (k === 'n') initialState.customer.name = v;
    else if (k === 'm') initialState.customer.phone = v;
    else if (k === 'e') initialState.customer.email = v;
    else if (k === 'a') initialState.customer.address = v;
    else if (k === 'z') initialState.customer.postCode = v;
    else if (k === 'l') initialState.customer.place = v;
    else if (k === 'r') initialState.wantsRings = !!v;
    else if (k[0] === 'p') {
      const product = initialState.products.find((p) => `p${parseInt(p.slug, 36) % 10000}` === k);
      if (product) {
        const q = { d: '1', v: 1 };
        setNumVal(q, v);
        initialState.lines.push({
          category: initialState.categories.find((c) => c.products.includes(product.slug))!.slug,
          product: product.slug,
          quantity: q,
          dimensions: '',
          price: 0,
          priceTotal: 0,
          weight: 0
        });
      }
    }
  });
  initialState.lines.forEach((l) => updateLine(l, initialState));
}

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    addLine(state) {
      if (state.newLine.product) {
        const line = state.lines.find((l) => l.product === state.newLine.product);
        if (line) {
          setNumVal(line.quantity, line.quantity.v + state.newLine.quantity.v);
        } else {
          state.lines.push(state.newLine);
        }
        state.newLine = {
          category: '',
          dimensions: '',
          price: 0,
          priceTotal: 0,
          product: '',
          quantity: { d: '1', v: 1 },
          weight: 0
        };
        if (line) {
          updateLine(line, state);
        }
      }
    },
    removeLine(state, action: PayloadAction<number>) {
      state.lines = state.lines.filter((_, i) => i !== action.payload);
      updateTotals(state);
    },
    setCustomer(state, action: PayloadAction<[string, string, string, string, string, string]>) {
      state.customer.name = action.payload[0];
      state.customer.phone = action.payload[1];
      state.customer.email = action.payload[2];
      state.customer.address = action.payload[3];
      state.customer.postCode = action.payload[4];
      state.customer.place = action.payload[5];
    },
    setLine(state, action: PayloadAction<[number, string, string, string]>) {
      const line: ProductLine = action.payload[0] < 0 ? state.newLine : state.lines[action.payload[0]];
      line.category = action.payload[1];
      line.product = action.payload[2];
      setNumVal(line.quantity, action.payload[3]);
      updateLine(line, state);
    },
    setWantsRings(state, action: PayloadAction<boolean>) {
      state.wantsRings = !!action.payload;
      updateTotals(state);
    }
  }
});

export const { addLine, removeLine, setCustomer, setLine, setWantsRings } = productsSlice.actions;

export const selectProducts = (state: RootState) => state.products;

export default productsSlice.reducer;
